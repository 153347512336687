import { Helmet } from 'react-helmet';
import './client.css'
import client1 from '../home/images/client/1.png';
import client2 from '../home/images/client/2.png';
import client3 from '../home/images/client/3.png';
import client4 from '../home/images/client/4.png';
import client5 from '../home/images/client/5.png';
import client6 from '../home/images/client/6.png';
import client7 from '../home/images/client/7.png';
import client8 from '../home/images/client/8.png';
import client9 from '../home/images/client/9.png';
import client10 from '../home/images/client/10.png';
import client11 from '../home/images/client/11.png';
import client12 from '../home/images/client/12.png';
import client13 from '../home/images/client/13.png';
import client14 from '../home/images/client/14.png';
import client15 from '../home/images/client/15.png';
import client16 from '../home/images/client/16.png';
import client17 from '../home/images/client/17.png';
import client18 from '../home/images/client/18.png';
import client19 from '../home/images/client/19.png';
import client20 from '../home/images/client/20.png';
import client21 from '../home/images/client/21.png';
import client22 from '../home/images/client/22.png';
import client23 from '../home/images/client/23.png';
import client24 from '../home/images/client/24.png';


function Client() {
    return (


        <div >

            <section className='clients'>
                <div className='container cli'>
                    <p id='clientheading'><b>Our Clients</b></p>
                    <div className='row'>
                        <div className='col-md-2'>
                            <img src={client1} alt="" />
                        </div>

                        <div className='col-md-2'>
                            <img src={client2} alt="" />
                        </div>

                        <div className='col-md-2'>
                            <img src={client3} alt="" />
                        </div>

                        <div className='col-md-2'>
                            <img src={client4} alt="" />
                        </div>

                        <div className='col-md-2'>
                            <img src={client5} alt="" />
                        </div>

                        <div className='col-md-2'>
                            <img src={client6} alt="" />
                        </div>
                    </div>

                    <div className='row' style={{marginTop:'2%'}}>
                        <div className='col-md-2'>
                            <img src={client7} alt="" />
                        </div>

                        <div className='col-md-2'>
                            <img src={client8} alt="" />
                        </div>

                        <div className='col-md-2'>
                            <img src={client9} alt="" />
                        </div>

                        <div className='col-md-2'>
                            <img src={client10} alt="" />
                        </div>

                        <div className='col-md-2'>
                            <img src={client11} alt="" />
                        </div>

                        <div className='col-md-2'>
                            <img src={client12} alt="" />
                        </div>
                    </div>

                    <div className='row' style={{ marginTop: '2%' }}>
                        <div className='col-md-2'>
                            <img src={client13} alt="" />
                        </div>

                        <div className='col-md-2'>
                            <img src={client14} alt="" />
                        </div>

                        <div className='col-md-2'>
                            <img src={client15} alt="" />
                        </div>

                        <div className='col-md-2'>
                            <img src={client16} alt="" />
                        </div>

                        <div className='col-md-2'>
                            <img src={client17} alt="" />
                        </div>

                        <div className='col-md-2'>
                            <img src={client18} alt="" />
                        </div>
                    </div>

                    <div className='row' style={{ marginTop: '2%' }}>
                        <div className='col-md-2'>
                            <img src={client19} alt="" />
                        </div>

                        <div className='col-md-2'>
                            <img src={client20} alt="" />
                        </div>

                        <div className='col-md-2'>
                            <img src={client21} alt="" />
                        </div>

                        <div className='col-md-2'>
                            <img src={client22} alt="" />
                        </div>

                        <div className='col-md-2'>
                            <img src={client23} alt="" />
                        </div>


                        <div className='col-md-2'>
                            <img src={client24} alt="" />
                        </div>
                    </div>

                </div>
            </section>
        </div>
    )
}
export default Client;