



import React, { useEffect } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';

import port1 from './images/1.png';
import port2 from './images/2.jpg';
import port3 from './images/3.jpg';
import port4 from './images/4.jpg';
import port5 from './images/5.jpg';
import port6 from './images/6.jpg';

import './section4portfolio.css';

function Por() {
    useEffect(() => {
        AOS.init({ duration: 2000 });
    }, []);

    return (
        <div className='por'>
            <div className="container">
                <div className="row">
                    <h2 id='Portfoliosheaeding'><b>Our Portfolios</b></h2>
                    <div style={{ marginTop: '3%' }}></div>
                    <div className="col-md-4">
                        <div data-aos="fade-up" data-aos-anchor-placement="top-bottom">
                            <div className="portcontainer">
                                <div className="scrollable img1">
                                    <img src={port1} alt="" className="img-responsive" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div data-aos="fade-up" data-aos-anchor-placement="top-bottom">
                            <div className="portcontainer">
                                <div className="scrollable img1">
                                    <img src={port2} alt="" className="img-responsive" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div data-aos="fade-up" data-aos-anchor-placement="top-bottom">
                            <div className="portcontainer">
                                <div className="scrollable img1">
                                    <img src={port3} alt="" className="img-responsive" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container" style={{ marginTop: '4%' }}>
                <div className="row">
                    <div className="col-md-4">
                        <div data-aos="fade-up" data-aos-anchor-placement="top-bottom">
                            <div className="portcontainer">
                                <div className="scrollable img1">
                                    <img src={port4} alt="" className="img-responsive" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div data-aos="fade-up" data-aos-anchor-placement="top-bottom">
                            <div className="portcontainer">
                                <div className="scrollable img1">
                                    <img src={port5} alt="" className="img-responsive" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div data-aos="fade-up" data-aos-anchor-placement="top-bottom">
                            <div className="portcontainer">
                                <div className="scrollable img1">
                                    <img src={port6} alt="" className="img-responsive" />
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Add more portfolio images as needed */}
                </div>

            </div>
            <br></br>
        </div>
    )
}

export default Por;
