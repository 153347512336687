
import './video.css';

import { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Helmet } from 'react-helmet';
import vidimg from './img/1.png';

import Cli from '../home/cli';
function Videopage() {

    useEffect(() => {
        AOS.init({ duration: 1000 });
    }, []);

    return (
        <div>
            <Helmet>
                <title>Best Web Design Company in Chennai,Digital Marketing agency in chennai </title>
                <meta name="description" content="WiseWebTek-Best Website Design Company in Chennai,Digital Marketing Agency Chennai,Search Engine optimization SEO Company in Chennai,India." />
                <meta name="keywords" content="Web Design Company in Chennai,Digital Marketing Companies in Chennai,Website Design Companies in Chennai, SEO Companies in Chennai, Digital Marketing Company Chennai, Best Web Design Company Chennai,No:1 Digital Marketing Company in Chennai,Top 10 Web Develpment Company in Chennai,India. " />
            </Helmet>




            <section id="hostingfull" >
                <div className="container">
                    <div className="row" style={{alignItems:'center'}}>
                        <p id='videoheading'><b>Video Editing</b></p>
                        <div style={{ marginTop: '3%' }}></div>

                        <div className="col-lg-6 ">
                            <img src={vidimg} alt="" style={{ borderRadius: '10px' }} height={'100%'} width={'100%'} data-aos="zoom-in-down" />

                        </div>

                        <div className="col-lg-6 pt-4 pt-lg-0 " id='videocontent' data-aos="zoom-in-down">

                            <p className='hosting-sub-heading'><b>Professional Video Editing services in Chennai</b></p>
                            <p>
                                Our experienced editors use best editing software and techniques to transform your raw footage into high-quality videos. Whether it's a corporate video, promotional content, wedding video, short film, or any other type of video project, we have the expertise to bring your vision to life
                            </p>


                        </div>

                    </div>

                    <div>
                        <div className="video-full">
                            <div className='container-fluid'>
                                <div className='row'>
                                    <div style={{ marginTop: '5%' }}></div>

                                    <div className='col-md-4' data-aos="fade-up">
                                        <iframe width="100%" height="300px"
                                            src="https://www.youtube.com/embed/aExXcUkGcgY" title="Launch of Corporate Video" frameborder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                            allowfullscreen style={{ borderRadius: '10px' }} ></iframe>
                                    </div>

                                    <div className='col-md-4' data-aos="fade-down">
                                        <iframe width="100%" height="300px"
                                            src="https://www.youtube.com/embed/p-sH9Lq6e9M?si=0zJ3KlaNvH7UQzdX" title="Launch of Corporate Video" frameborder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                            allowfullscreen style={{ borderRadius: '10px' }} ></iframe>
                                    </div>

                                    <div className='col-md-4' data-aos="fade-up">
                                        <iframe width="100%" height="300px"
                                            src="https://www.youtube.com/embed/h3NV_-6JdBs?si=MB8FBLMFF11hONj9" title="Launch of Corporate Video" frameborder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                            allowfullscreen style={{ borderRadius: '10px' }} ></iframe>
                                    </div>

                                </div>
                                <br>
                                </br>




                            </div>
                            <div className='service__box1'><button id='videobtn'><a class="common_btn fix_btn" href="https://www.youtube.com/channel/UC0UgReCUpvi8bqLOlVanp-A" target="_blank">See More</a></button></div>


                            <br></br>

                            <div>
                                <Cli />
                            </div>
                        </div>
                    </div>


                </div>
                <br></br>
            </section>




        </div>
    )
}
export default Videopage;


