import icon1 from './images/front-end.png';
import icon2 from './images/digital.png';
import icon3 from './images/cloud-database.png';
import icon4 from './images/e-commerce.png';
import icon5 from './images/seo.png';
import icon6 from './images/socialmedia.png';

import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import './section3service.css';

import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from "react";

function Section3() {

    useEffect(() => {
        AOS.init({ duration: 1500 });
    }, []);

    return (
        <div className="service component__space" id="Services">

            <div>
                <div className="container">
                    <div className="row">
                        <div className="heading">
                            <p className="heading"><b>Our Services</b></p>
                        </div>

                        <div className="col-md-4" data-aos="fade-up-left">
                            <div className="service__box pointer " >
                                <div className="icon">
                                    <img src={icon1} alt="My Image" id='icons1'  />

                                </div>
                                <div className="service__meta">
                                    <h1 className="service__text">Web Design</h1>
                                    <p className="p service__text p__color">
                                        Do you want your Website to be Compatible with Mobile and Tablet ? We can provide you the solution.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4" data-aos="fade-up-left">
                            <div className="service__box pointer ">
                                <div className="icon">
                                    <img src={icon2} alt="My Image" id='icons1' className="text-center" />
                                </div>
                                <div className="service__meta">
                                    <h1 className="service__text">Digital Marketing</h1>
                                    <p className="p service__text p__color">
                                        Reach the right audience at the right time with right digital marketing strategies that drive results and maximize your ROI.</p>

                                </div>
                            </div>
                        </div>

                        <div className="col-md-4" data-aos="fade-up-left">
                            <div className="service__box pointer ">
                                <div className="icon">
                                    <img src={icon3} alt="My Image" id='icons1' className="text-center" />
                                </div>
                                <div className="service__meta">
                                    <h1 className="service__text">Web Hosting</h1>
                                    <p className="p service__text p__color">
                                        We provide the Easy Affordable Website Hosting, windows and Linux hosting with the latest software.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="container" >
                    <div className="row" style={{ marginTop: '2%' }}>
                        <div className="col-md-4" data-aos="fade-up-left">
                            <div className="service__box pointer ">
                                <div className="icon">
                                    <img src={icon4} alt="My Image" id='icons1' className="text-center" />
                                </div>
                                <div className="service__meta">
                                    <h1 className="service__text">E-Commerce Web Development</h1>
                                    <p className="p service__text p__color">
                                        E-Commerce Web Design, Payment Gateway Integration and Shopping Cart Solutions.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4" data-aos="fade-up-left">
                            <div className="service__box pointer ">
                                <div className="icon">
                                    <img src={icon5} alt="My Image" id='icons1' className="text-center" />
                                </div>
                                <div className="service__meta">
                                    <h1 className="service__text">Search Engine Optimization (SEO)</h1>
                                    <p className="p service__text p__color">
                                        Do you want your Website to be on top in Google Listing ? We can provide you the best SEO Services .
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4" >
                            <div className="service__box pointer " data-aos="fade-up-left">
                                <div className="icon">
                                    <img src={icon6} alt="My Image" id='icons1' className="text-center" />
                                </div>
                                <div className="service__meta">
                                    <h1 className="service__text">
                                        Social Media Marketing</h1>
                                    <p className="p service__text p__color">
                                        We can create your identity in all social medias and help you to market your product/services through Social medias.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-12' style={{ textAlign: 'center' }}>
                            <Link to="/webdesign" className='btn btn-outline-primary btns'>
                                Read more</Link>
                        </div>
                    </div>

                </div>
            </div>
            <br></br>
        </div>
    )
}

export default Section3;