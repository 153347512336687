import './socialmediamorketing.css'

import { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Helmet } from 'react-helmet';
import social from '../img/social-media.jpg';
import po1 from '../img/so1.png';
import po2 from '../img/so2.png';
import po3 from '../img/so3.png';
import Cli from '../../home/cli';
function Smm() {

    useEffect(() => {
        AOS.init({ duration: 2000 });
    }, []);

    return (
        <div>

            <Helmet>

                <title>Social Media Marketing Companies in Chennai,Digital Marketing Companies Chennai,Design a Website | Web Design Firms</title>
                <meta name="description" content="Social Media Marketing Companies in Chennai, Web Design Company in Chennai, WiseWebTek provides the Best Website Design,Search Engine Optimization SEO,Responsive Mobile Website Design and Social Media Marketing Services in Chennai." />
                <meta name="keywords" content="Social Media Marketing Companies in Chennai,Digital Marketing Companies in Chennai,web design chennai,Social Media Marketing SMM Services in Chennai,Best Social Media Marketing Company in chennai,web design companies in chennai" />
           
            </Helmet>

            <section id="smmfull">
                <div className="container">
                    <div className="row" >

                        <h3 id='smmheading' data-aos="zoom-out-down">Social Media Markting Company In Chennai</h3>

                        <div style={{ marginTop: '4%' }}></div>

                        <div className="col-lg-6 order-2 order-lg-1">
                            <img src={social} alt="" width={'100%'} style={{ borderRadius:'10px' }} data-aos="fade-up-right" />
                        </div>

                        <div className="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 " id='smmcontent' data-aos="zoom-out-down">
                            <p id='smmsubheading'><b>Social Media Markting Company In Chennai</b></p>
                            <p>
                                Social Media Marketing Services Chennai<br></br>

                                Attract the Right Customers through Social Media Marketing
                            </p>
                            <p>
                                Social Media Marketing is the process of gaining website traffic or attention through social media sites.

                                Many Powerful Social Medias are available nowadays and reaching the right customers are easy through social
                                medias. You can create your brand identity through the social medias, You can regularly post the news and
                                updates through social medias etc. Social media is a platform where you can be in touch with your customers in
                                a regular basis.Its a wonderful platform to market your Products/ Services.</p>

                            <p id='smmsubheading'><b>What We are Offering to You</b></p>

                            <p>We can create your identity in all powerful social medias and help and support you to market your
                                product/services through Social medias.</p>

                            <p>We can support you to build the right followers and we can guide you with all strategies to generate business
                                through social media marketing.</p>

                        </div>

                    </div>


                </div>

                <div data-aos="zoom-out-down">
                    <p id='seoheading'><b>Our Portfolios</b></p>
                    <div className="container">
                        <div className="row" style={{ marginTop: '5%', marginBottom: '5%' }}>
                            <div className="col-md-4">
                                <div className="containerk">
                                    <div className="scrollable" id="imgSOCIAL">
                                        <img src={po1} alt="" width={'100%'} height={'200%'} />
                                    </div>
                                </div>

                            </div>
                            <div className="col-md-4">
                                <div className="containerk">
                                    <div className="scrollable" id="imgSOCIAL">
                                        <img src={po2} alt="" width={'100%'} height={'200%'} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="containerk">
                                    <div className="scrollable" id="imgSOCIAL">
                                        <img src={po3} alt="" width={'100%'} height={'200%'} />
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div>
                   
                        <Cli />
                  
                </div>



                <br></br>
            </section>
        </div>
    )
}
export default Smm;