// Layout.js
import React from 'react';
import Navgat from './navigation';
import Section8 from './section8footer'; // Adjust the path if needed

const Layout = ({ children }) => {
    return (
        <div className="App">
            <Navgat />
            <main>{children}</main>
            <Section8 />
        </div>
    );
};

export default Layout;
