import './ecommerce.css';
import port1 from '../img/4.jpg';

import ecomimg from '../img/ecomm-01.png';

import { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Helmet } from 'react-helmet';
import Cli from '../../home/cli';
function Ecommerce() {

    useEffect(() => {
        AOS.init({ duration: 2000 });
    }, []);

    return (
        <div>
            <Helmet>
                <title>E-commerce Website Design companies in Chennai,Best web design company in chennai,Contact now</title>
                <meta name="description" content="E-commerce Website Design Chennai,web design companies in chennai,seo chennai, Search Engine Optimization,Social Media Marketing and Online Advertising Solution provider in Chennai - WiseWebTek,Contact us now" />
                <meta name="keywords" content="Web design companies in chennai,E-commerce Website Design and Deveopment Company Chennai,Adyar,Digital Marketing Companies in Chennai,Top 10 Search Engine Optimization SEO Services in Chennai" />
            </Helmet>
            <section id="ecomfull"  >
                <div className="container">
                    <div className="row" >
                        <p className='ecomheading'><b>E-Commerce Website Development</b></p>
                        <div style={{ marginTop: '3%' }}></div>

                        <div className="col-lg-6 order-2 order-lg-1">

                            <img src={ecomimg} alt="My Image" width={'100%'} height={'84%'} data-aos="zoom-in-down" />
                        </div>
                        <div className="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 " data-aos="zoom-in-down">
                            <p><b>E-Commerce Web Design Chennai</b></p>

                            <p>
                                With our advanced E-commerce solutions, we can help you to boost your business by using internet.
                            </p>

                            <p>
                                <b>E-Commerce Websites</b> We can create the best E-Commerce website for your business. We can also guide you with
                                the internet marketing solutions so that you can attract the right customers online.
                            </p>

                            <p><b>Payment Gateway Integration</b> Integrate Payment Gateway with your website and help the customer to pay for your
                                product/service instantly. Integartion of payment gateway can lead to instant purchases and there by you can
                                increase the sales online. We can guide you to integrate online payment system with your website.</p>

                            <p><b>Shopping Cart Solutions</b> We can Create and Integrate the Shopping cart with your website and thereby you can
                                help your customer to make the purchases online.</p>
                        </div>

                    </div>
                </div>

                <div data-aos="zoom-in-down"  style={{marginBottom :'5%'}}>
                    <div className="container" >
                        <div className="row" >

                            <div className="col-md-6">
                                <div className="ecomcontainer">
                                    <div className="ecomscrollable" id="img1">
                                        <img src={port1} alt="My Image" width={'100%'} height={'500%'} />
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-6">

                            </div>


                        </div>
                    </div>

                </div>

                <div>
                    <Cli />
                </div>

                <br></br>
            </section>

        </div>

    )
}
export default Ecommerce;

