import './logocreative.css';

import digital from './img/digital.png';

import icon1 from '../../home/images/digital.png';
import icon2 from '../../home/images/seo.png';
import icon3 from '../../home/images/socialmedia.png';


import { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Helmet } from 'react-helmet';

import Cli from '../../home/cli';

function Logocreative() {

    useEffect(() => {
        AOS.init({ duration: 2000 });
    }, []);

    return (
        <div>

            <Helmet>
                <title>Best Web Design companies chennai,Digital Marketing Companies in Chennai,Web Design,SEO Companies Chennai</title>
                <meta name="description" content="web design firm chennai-WiseWebTek provides Digital Marketing,Website Design,Search Engine Optimization SEO,Mobile Responsive Web Design,Social Media Marketing,Ecommerce Web Design and Digital Marketing Services in Chennai,India-Call us Now." />
                <meta name="keywords" content="web design companies in chennai,best web development company,Digital Marketing Companies in Chennai,Website Design Companies in Chennai, SEO Companies in Chennai, Digital Marketing Company Chennai, Web Design Company Chennai " />
            </Helmet>

            <section id="logofull" >
                <div className="container">
                    <div className="row" style={{ alignItems: 'center' }}>


                        <p id='hostingheading'><b>Digital Marketing</b></p>
                        <div style={{ marginTop: '3%' }}></div>

                        <div className="col-lg-6 ">
                            <img src={digital} alt="" style={{ borderRadius: '10px' }} width={'100%'} height={'80%'} data-aos="zoom-in-down" />
                        </div>

                        <div className="col-lg-6 pt-4 pt-lg-0 " id='logo-content' data-aos="zoom-in-down" >


                            <p id='logo-sub-heading'><b>Digital Markerting Company in Chennai</b></p>
                            <p>We can provide you the best Digital markerting Services . WiseWebTek, a leading professional web design and digital marketing company in chennai, providing the best web design and digital marketing services at affordable price.</p>
                            <p id='logo-sub-heading'><b>3 Important Digital Marketing strategies</b></p>
                            <ul style={{ listStyleType: "square" }}>
                                <li>Search Engine Optimization (SEO)</li>
                                <li>Social Media Marketing</li>
                                <li>Google Adwords Adversting</li>

                            </ul>



                        </div>
                    </div>

                    <div className='row' style={{ marginTop: '5%' }}>
                        <div className='col-md-2'></div>
                        <div className='col-md-8'>
                            <iframe width="100%" style={{borderRadius:'10px'}} height="315" src="https://www.youtube.com/embed/b3nff64wolk?si=rX4T0Ea3vvCzokKd" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                        </div>
                    </div>

                    <div className="service component__space" id="Services">

                        <div className="container" >
                            <div className="row" style={{ marginTop: '2%' }}>

                                <div className="col-md-4" data-aos="fade-up-left">
                                    <div className="service__box pointer ">
                                        <div className="icon">
                                            <img src={icon1} alt="My Image" id='icons1' className="text-center" />
                                        </div>
                                        <div className="service__meta">
                                            <h1 className="service__text">Digital Marketing</h1>
                                            <p className="p service__text p__color">
                                                Reach the right audience at the right time with right digital marketing strategies that drive results and maximize your ROI.</p>

                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4" data-aos="fade-up-left">
                                    <div className="service__box pointer ">
                                        <div className="icon">
                                            <img src={icon2} alt="My Image" id='icons1' className="text-center" />
                                        </div>
                                        <div className="service__meta">
                                            <h1 className="service__text">Search Engine Optimization (SEO)</h1>
                                            <p className="p service__text p__color">
                                                Do you want your Website to be on top in Google Listing ? We can provide you the best SEO Services .
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-4" >
                                    <div className="service__box pointer " data-aos="fade-up-left">
                                        <div className="icon">
                                            <img src={icon3} alt="My Image" id='icons1' className="text-center" />
                                        </div>
                                        <div className="service__meta">
                                            <h1 className="service__text">
                                                Social Media Marketing</h1>
                                            <p className="p service__text p__color">
                                                We can create your identity in all social medias and help you to market your product/services through Social medias.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>

                        <br></br>
                    </div>


                    <div>
                        <Cli />

                    </div>

                </div>
                <br></br>
            </section>
        </div>
    )
}

export default Logocreative;


