


import './section1.css';
import MyImage from './images/img2.png';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import { useEffect } from 'react';

import AOS from 'aos';
import 'aos/dist/aos.css';

function Section1() {

    useEffect(() => {
        AOS.init({ duration: 1500 });
    }, []);

    return (
        <div className='bgfull'>
            <div className='container' >
                <div class="row">
                    <div className='col-md-7 ' data-aos="fade-up" style={{ marginTop: '15%' }}>
                        <p style={{ fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif', fontSize: '36px', lineHeight: 'normal', letterSpacing: '0px' }}>Web Design and Digital Marketing Company in Chennai</p>
                        <p style={{ fontFamily: "'Open Sans', sans-serif", fontSize: '18px' }}>
                            WiseWebTek is a Software Company, Provides E-Commerce Solutions. With Our best
                            software technologies, we will help the Business community
                            to reach their goals faster. Having supported many Companies to create their
                            Online solutions, we will continue to provide the best support to the global
                            business community.
                        </p>
                       
                        <Link to="/contact" class="btn btn-outline-primary s1btns">
                            <b>Enquire Now</b></Link>
                    </div>
                    <div className='col-md-5' style={{ marginTop: '11%',marginBottom:'40px' }}>
                        <img src={MyImage} alt="" id='home-tool-img' />

                    </div>

                </div>
            </div>
        </div>
    )
}
export default Section1;