import React, { useState } from 'react';
import './contact.css';
import phone from './img/phone-call.png';
import email from './img/email.png';
import address from './img/gps.png';

function Contactpage() {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        message: ''
    });

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        fetch('https://wisewebtek.in/php/mail.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(formData)
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`Server responded with status: ${response.status}`);
                }
                return response.json();
            })
            .then(data => {
                if (data.status === 'success') {
                    alert('Form submitted successfully!');
                    setFormData({
                        name: '',
                        email: '',
                        phone: '',
                        message: ''
                    });
                } else {
                    alert('There was an error: ' + data.message);
                }
            })
            .catch(error => {
                console.error('Error:', error);
                alert('There was an error submitting the form.');
            });
    };

    return (
        <div className="container">
            <div className="row">
                <div className="col-md-4 col-sm-12">
                    <div className="contact-container">
                        <h2>Contact Us</h2>
                        <div className="contact-detail">
                            <p><strong>Phone:</strong></p>
                            <p className="contact-info"><img src={phone} alt="Phone" className="contact-icon" /> +91 9080248247</p>
                        </div>
                        <div className="contact-detail">
                            <p><strong>Email:</strong></p>
                            <p className="contact-info"><img src={email} alt="Email" className="contact-icon" /> contact@wisewebtek.com</p>
                        </div>
                        <div className="contact-detail">
                            <p><strong>Address:</strong></p>
                            <p className="contact-info"><img src={address} alt="Address" className="contact-icon" /> No. 20/9, Janaki Complex, Sardar Patel Road, Adyar Chennai-600020, India.</p>
                        </div>
                    </div>
                </div>

                <div className="col-md-2"></div>
                <div className="col-md-5">
                    <h2>Enquiry Form</h2>
                    <form onSubmit={handleSubmit}>
                        <div>
                            <label>Name:</label><br />
                            <input type="text" name="name" className="box2" value={formData.name} onChange={handleChange} /><br />
                        </div><br />
                        <div>
                            <label>Email:</label><br />
                            <input type="email" name="email" className="box2" value={formData.email} onChange={handleChange} /><br />
                        </div><br />
                        <div>
                            <label>Phone:</label><br />
                            <input type="number" name="phone" className="box2" value={formData.phone} onChange={handleChange} /><br />
                        </div><br />
                        <div>
                            <label>Message:</label><br />
                            <textarea name="message" className="form-control" rows="3" value={formData.message} onChange={handleChange}></textarea><br />
                        </div>
                        <button type="submit" className="mb-4" id="navenqbtn">Submit</button>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default Contactpage;
