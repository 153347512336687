

import React, { useEffect } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';

import port1 from '../home/images/1.png';
import port2 from '../home/images/2.jpg';
import port3 from '../home/images/3.jpg';
import port4 from '../home/images/4.jpg';
import port5 from '../home/images/5.jpg';
import port6 from '../home/images/6.jpg';

import port7 from './img/7.png';
import port8 from './img/8.png';
import port9 from './img/9.png';
import port10 from './img/10.png';
import port11 from './img/11.png';
import port12 from './img/12.png';
import './portfolio.css';

function Portfolio() {
    useEffect(() => {
        AOS.init({ duration: 2000 });
    }, []);

    return (
        <div className='portfoliofull'>
            <div className="container">
                <div className="row">
                    <p id='Portfoliosheaeding'><b>Portfolios</b></p>
                    <div style={{ marginTop: '3%' }}></div>
                    <div className="col-md-4">
                        <div data-aos="fade-up" data-aos-anchor-placement="top-bottom">
                            <div className="portcontainer">
                                <div className="scrollable img1">
                                    <img src={port1} alt="" className="img-responsive" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div data-aos="fade-up" data-aos-anchor-placement="top-bottom">
                            <div className="portcontainer">
                                <div className="scrollable img1">
                                    <img src={port2} alt="" className="img-responsive" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div data-aos="fade-up" data-aos-anchor-placement="top-bottom">
                            <div className="portcontainer">
                                <div className="scrollable img1">
                                    <img src={port3} alt="" className="img-responsive" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container" style={{ marginTop: '4%' }}>
                <div className="row">
                    <div className="col-md-4">
                        <div data-aos="fade-up" data-aos-anchor-placement="top-bottom">
                            <div className="portcontainer">
                                <div className="scrollable img1">
                                    <img src={port4} alt="" className="img-responsive" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div data-aos="fade-up" data-aos-anchor-placement="top-bottom">
                            <div className="portcontainer">
                                <div className="scrollable img1">
                                    <img src={port5} alt="" className="img-responsive" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div data-aos="fade-up" data-aos-anchor-placement="top-bottom">
                            <div className="portcontainer">
                                <div className="scrollable img1">
                                    <img src={port6} alt="" className="img-responsive" />
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Add more portfolio images as needed */}
                </div>

            </div>

            <div className="container" style={{ marginTop: '4%' }}>
                <div className="row">
                    <div className="col-md-4">
                        <div data-aos="fade-up" data-aos-anchor-placement="top-bottom">
                            <div className="portcontainer">
                                <div className="scrollable img1">
                                    <img src={port7} alt="" className="img-responsive" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div data-aos="fade-up" data-aos-anchor-placement="top-bottom">
                            <div className="portcontainer">
                                <div className="scrollable img1">
                                    <img src={port8} alt="" className="img-responsive" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div data-aos="fade-up" data-aos-anchor-placement="top-bottom">
                            <div className="portcontainer">
                                <div className="scrollable img1">
                                    <img src={port9} alt="" className="img-responsive" />
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Add more portfolio images as needed */}
                </div>

            </div>

            <div className="container" style={{ marginTop: '4%' }}>
                <div className="row">
                    <div className="col-md-4">
                        <div data-aos="fade-up" data-aos-anchor-placement="top-bottom">
                            <div className="portcontainer">
                                <div className="scrollable img1">
                                    <img src={port10} alt="" className="img-responsive" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div data-aos="fade-up" data-aos-anchor-placement="top-bottom">
                            <div className="portcontainer">
                                <div className="scrollable img1">
                                    <img src={port11} alt="" className="img-responsive" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div data-aos="fade-up" data-aos-anchor-placement="top-bottom">
                            <div className="portcontainer">
                                <div className="scrollable img1">
                                    <img src={port12} alt="" className="img-responsive" />
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Add more portfolio images as needed */}
                </div>

            </div>
            <br></br>
        </div>
    )
}

export default Portfolio;
