import './graphicdesign.css'

import { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Helmet } from 'react-helmet';
import Cli from '../../home/cli';

import graphic from './img/graphic.png'
import gra1 from './img/social1.jpeg'
import gra2 from './img/social2.jpeg'
import gra3 from './img/social3.jpeg'


import logo1 from './img/logo1.png'
import logo2 from './img/logo2.png'
import logo3 from './img/logo3.png'
import logo4 from './img/logo4.png'
import logo5 from './img/logo5.png'
import logo6 from './img/logo6.png'

function Websecurity() {

  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);


  return (
    <div >
      <Helmet>

        <title>web design companies in chennai,Digital Marketing agencies in Chennai,Hacking Protection,Web Design chennai</title>
        <meta name="description" content="web design companies in chennai,Hacking Protection,Web Security,Website Design,Search Engine Optimization SEO,Mobile Responsive Web Design,Social Media Marketing,Ecommerce Web Design and Digital Marketing Services" />
        <meta name="keywords" content="Hacking Protection,Digital Marketing Companies in Chennai,Web Security,Website Design Companies in Chennai, SEO Companies in Chennai, Digital Marketing Company Chennai, Web Design Company Chennai " />

      </Helmet>

      <section id="websecuritybgcolor">
        <div className="container">
          <div className="row" style={{ alignItems: 'center' }}>
            <p id='web-heading' data-aos="fade-up-left"><b>Graphic Design</b></p>
            <div style={{ marginTop: '3%' }}></div>
            <div className="col-lg-6 order-2 order-lg-1">

              <img src={graphic} alt="" style={{ borderRadius: '10px' }} className='img-fluid ' width={'100%'} height={'84%'} data-aos="fade-up-right" />

            </div>
            <div className="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 web-content" id="web-security-content" data-aos="fade-up-left">
              <p id='web-subheading'><b>Graphic design / Creative design services in Chennai</b></p>
              <p>
                Our expert graphic designers specialize in creating visually stunning graphics designs that communicate your brand message effectively. From logos and branding materials to marketing collateral and digital assets, we ensure that every design element reflects the essence of your brand.
              </p>
              <p>
                Located in the vibrant city of Chennai, we are committed to delivering best creative solutions tailored to meet your unique needs. Whether you're a startup, small business, or a large corporation, our team of skilled professionals is here to elevate your brand presence and help you stand out in today's competitive market.
              </p>
            </div>
          </div>


          <div data-aos="zoom-out-down">
            <p id='seoheading'><b>Some of Our Portfolios</b></p>
            <div className="container">
              <div className="row" style={{ marginTop: '3%' }}>
                <div className="col-md-2">
                  <img src={logo1} alt="" style={{borderRadius:'10px'}} width={'100%'} />
                </div>
                <div className="col-md-2">
                  <img src={logo2} alt="" style={{ borderRadius: '10px' }} width={'100%'} />
                </div>
                <div className="col-md-2">
                  <img src={logo3} alt="" style={{ borderRadius: '10px' }} width={'100%'} />
                </div>
                <div className="col-md-2">
                  <img src={logo4} alt="" style={{ borderRadius: '10px' }} width={'100%'} />
                </div>
                <div className="col-md-2">
                  <img src={logo5} alt="" style={{ borderRadius: '10px' }} width={'100%'} />
                </div>
                <div className="col-md-2">
                  <img src={logo6} alt="" style={{ borderRadius: '10px' }} width={'100%'} />
                </div>

              </div>
              <div className="row" style={{ marginTop: '2%', marginBottom: '5%' }}>
                <div className="col-md-4">
                  <img src={gra1} alt="" width={'100%'} />


                </div>
                <div className="col-md-4">
                  <img src={gra2} alt="" width={'100%'} />
                </div>
                <div className="col-md-4">
                  <img src={gra3} alt="" width={'100%'} />
                </div>
              </div>
            </div>
          </div>
          <div >
            <Cli />
          </div>

        </div>
        <br></br>
      </section>
    </div>
  )
}
export default Websecurity;