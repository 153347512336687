// section8footer.js
import React from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import './section8footer.css'; // Ensure the path is correct
import Logo from './images/log1.jpeg'; // Ensure the path is correct

function Section8() {
    return (
        <footer className="text-center text-lg-start">
            <section id='footerfull'>
                <div className="container">
                    <div className="row">
                        <div id='jfifjfiof'></div>

                        <div className="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
                            <h6 className="text-uppercase fw-bold mb-4">
                                <Link to="/">  <img src={Logo} alt="WiseWebTek Logo" style={{ borderRadius: '10px' }} width={'220px'} /></Link>
                            </h6>
                            <p>
                                WiseWebTek is a Multi National Company, Provides E-commerce Solutions. With our best software technologies...
                            </p>
                        </div>

                        <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
                            <h6 className="text-uppercase fw-bold mb-4">Quick Links</h6>
                            <p><Link to="/" className="text-reset">Home</Link></p>
                            <p><Link to="/aboutus" className="text-reset">About Us</Link></p>
                            <p><Link to="/webdesign" className="text-reset">Services</Link></p>
                            <p><Link to="/portfolio" className="text-reset">Portfolios</Link></p>
                        </div>

                        <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mb-8">
                            <h6 className="text-uppercase fw-bold mb-5">

                            </h6>
                            <p><Link to="/client" className="text-reset">Client</Link></p>
                            <p><Link to="/contact" className="text-reset">Contact Us</Link></p>
                            <p><Link to="/enquire-now" className="text-reset">Enquire Now</Link></p>
                        </div>

                        <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
                            <h6 className="text-uppercase fw-bold mb-4">Contact</h6>
                            <p className="address">
                                <i className="fas fa-home me-3 icons"></i> No.20/9, Janaki Complex, Sardar Patel Road, Adyar Chennai-600020, India.
                            </p>
                            <p><i className="fas fa-envelope me-3 icons"></i> contact@wisewebtek.com</p>
                            <p><i className="fas fa-phone me-3 icons"></i> (+91) 9080248247</p>
                            <div>
                                <a href="https://www.facebook.com/profile.php?id=61550796950283" className="me-4 link-secondary" target="_blank" rel="noopener noreferrer">
                                    <i className="fab fa-facebook-f"></i>
                                </a>
                                <a href="https://twitter.com/wisewebtek" className="me-4 link-secondary" target="_blank" rel="noopener noreferrer">
                                    <i className="fab fa-twitter"></i>
                                </a>
                                <a href="https://wa.me/9443710868" className="me-4 link-secondary" target="_blank" rel="noopener noreferrer">
                                    <i className="fab fa-whatsapp"></i>
                                </a>
                                <a href="https://www.instagram.com/wisewebtekchennai/" className="me-4 link-secondary" target="_blank" rel="noopener noreferrer">
                                    <i className="fab fa-instagram"></i>
                                </a>
                                <a href="https://www.linkedin.com/in/wisewebtek-chennai-442a89194/" className="me-4 link-secondary" target="_blank" rel="noopener noreferrer">
                                    <i className="fab fa-linkedin"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="text-center p-2" id='footerfull1'>
                Copyright © 2024 WiseWebTek. All Rights Reserved.
            </div>
        </footer>
    );
}

export default Section8;
