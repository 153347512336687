
import { Swiper, SwiperSlide } from 'swiper/react';
import Testi from './images/manager.png';
import Testi1 from './images/manager (1).png';

import './section7testimonial.css';
import { Navigation } from "swiper";

import "swiper/css";
import "swiper/css/navigation";
function Section7() {


    return (
        <div>



            <div id='testimonialfull'>
                <div className='container' >
                    <div className='row'>

                        <div className='col-md-1'></div>
                        <div className='col-md-10' style={{ marginTop: '5%' }}>

                            <p className='text-center' id='testiheading' ><b>What Clients Say</b></p>
                            <Swiper
                                rewind={true}
                                navigation={true}
                                modules={[Navigation]}
                                className="mySwiper">

                                <SwiperSlide>
                                    <div className='containers text-center'>
                                    <img src={Testi} alt="My Image"  id='testi-icon' className='rounded mx-auto d-block' />
                                        <p className="headingcontent">Mr.M.R.Bhaarath</p>
                                        <p>Dear Rekha,
                                            I gave assignment to you to create the website.The website you created is really excellent and upto myexpectations.You did a wonderful job and you are able to deliver well in advance.
                                            You are one of the role model for the professionals.I appreciate your involvement, promptness and excellent co-operation on what ever assignment given to you. I am wishing you all success and best for the future projects and for all my friends.
                                            I am recommending you.</p>
                                    </div>
                                </SwiperSlide>

                                <SwiperSlide>
                                    <div className='containers text-center'>
                                    <img src={Testi1} alt="My Image"  id='testi-icon'  className='rounded mx-auto d-block'/>
                                        <p className="headingcontent">Mrs.Ramya Bhaarath</p>
                                        <p>MD- Great Life Insurance Broking Pvt Ltd</p>
                                        <p>Excellent service and friendly approach from WiseWebTek. I recommend the services of WiseWebTek to everyone as they have been tremendous in their handling of my project and they developed both web and mobile interfaces.
                                        </p>
                                    </div>
                                </SwiperSlide>

                                <SwiperSlide>
                                    <div className='containers text-center'>
                                    <img src={Testi} alt="My Image" id='testi-icon' className='rounded mx-auto d-block'/>
                                        <p className="headingcontent">Mr. Harish</p>
                                        <p>MD- Mallika Residency</p>
                                        <p> We were sincerely impressed with the super presentation as well as the clarity in the way they present the project to us delivering right on time. The kind of super work is the speciality with them.
                                        </p>
                                    </div>
                                </SwiperSlide>

                            </Swiper>
                        </div>
                        <div className='col-md-1'></div>
                    </div>
                </div>
                <br></br>
            </div>

        </div>



    );
}

export default Section7;
