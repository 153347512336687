import './hotalbookinengine.css';

import { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Helmet } from 'react-helmet';

function Hotalbooking() {

  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);

  return (
    <div>
      <Helmet>
        
        <title>web design Companies in Chennai,Hotel Booking Software | Hotel Booking Engine | Buy Hotel Booking Software for your website</title>
        <meta name="description" content="web design companies in chennai, best web development company in chennai,no:1 website design company in chennai,digital marketing company in chennai" />
        <meta name="keywords" content="web design companies in chennai,Digital Marketing Companies in Chennai,Hotel Booking Engine Software Chennai,Website Design Companies in Chennai,Search Engine Optimization Chennai" />

      </Helmet>

      <section id="hotalfull" >
        <div className="container">
          <div className="row">
            <h3 id='hotalheading' data-aos="fade-left">HOTEL BOOKING ENGINE CHENNAI</h3>
            <div style={{ marginTop: '3%' }}></div>
            <div className="col-lg-6 order-2 order-lg-1">
              <img src='https://wisewebtek.com/images/hotelbookingengineinchennai.jpg' alt="My Image" data-aos="fade-right" width={'100%'} height={'100%'} />

            </div>
            <div className="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 " id='hotelheading' data-aos="fade-left">
              <p id='hotalsubheading'><b>Hotel Booking Engine Chennai</b></p>
              <h6>Responsive Booking Engine</h6>
              <p>
                Convert your website into a powerful direct sales engine
              </p>
              <h6>Packages Booking Engine</h6>
              <p>
                Create and attractive customized packages for multiple destinations
              </p>
              <h6>Channel Connect</h6>
              <p>Manage hotel inventory, rates and availability across multiple OTAs efficiently from a single interface</p>
            </div>
          </div>
          <div className="container" id='hotelheading' data-aos="fade-left">
            <div className="row">
              <h6>Direct Connect</h6>
              <p>
                Feature packed tools that allow hotels to go Direct-to-customer 24x7, converting visits into bookings
                instantly and collecting payments in real-time through the booking engine, hosted IVR and email / SMS
                invoices.
              </p>
              <h6>Electronic Distribution</h6>
              <p>Comprehensive set of online distribution channels that make your rooms available to a relatively wider global
                audience and ensures that your hotel inventory is everywhere it needs to be.</p>
              <h6>Invoice Payment</h6>
              <p>Convert leads into qualified sale quickly.</p>
            </div>
            <div className='row'>
              <div className='col-md-12'>
                <button type="button" class="hotalbtn"> Contact </button>
              </div>
            </div>
          </div>
          <br></br>
        </div>
      </section>
    </div>
  )
}

export default Hotalbooking;