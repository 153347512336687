import './brochure-design.css'

import { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom

import Cli from '../../home/cli';
import Spam from './img/spam.jpg'

import bro1 from './img/brochur1.jpeg'

import bro2 from './img/brochure2.jpeg'

import bro3 from './img/brochure3.jpeg'

function Spamprotection() {

    useEffect(() => {
        AOS.init({ duration: 2000 });
    }, []);

    return (
        <div>
            <Helmet>


                <title>Digital Marketing Companies in Chennai,Web Design,SEO Company Chennai,Spam Protection</title>
                <meta name="description" content="Web design companies in chennai,Digital Marketing,Spam Protection,Website Design,Search Engine Optimization SEO,Mobile Responsive Web Design,Social Media Marketing,Ecommerce Web Design and Digital Marketing Services in Chennai,India-Call us Now." />
                <meta name="keywords" content="Best web design company in chennai,Digital Marketing Companies in Chennai,Spam Protection,Website Design Companies in Chennai, SEO Companies in Chennai, Digital Marketing Company Chennai, Web Design Company Chennai " />

            </Helmet>

            <section id="protectionfull" >
                <div class="container">
                    <div class="row">
                        <h3 id='spamheading' data-aos="fade-up-left"><b>Brochure Design</b></h3>
                        <div style={{ marginTop: '3%' }}></div>
                        <div class="col-lg-6 order-2 order-lg-1">
                            <img src={Spam} style={{ borderRadius: '10px' }} alt="" width={'100%'} height={'84%'} data-aos="fade-up-right" />

                        </div>
                        <div class="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1" id='protectioncontent' data-aos="fade-up-left" >
                            <p id='spamsubheading'><b>Brochure design services in Chennai</b></p>
                            <p>
                                Our team of talented designers works closely with you to understand your brand identity, objectives, and target audience. Whether you need a brochure for a product launch, event promotion, company profile, or any other purpose, we create custom designs that reflect the essence of your brand and leave a lasting impression.
                            </p>
                            <p id='spamsubheading'><b>Ready to elevate your brand with a stunning brochure design?</b></p>

                            <p>Contact us today to discuss your project requirements and let's create a brochure that sets you apart from the competition.
                            </p>
                            <div className='col-md-12' style={{ textAlign: 'right', marginTop: '20px' }}>
                                <Link to="/contact" className='btn btn-outline-primary btns' >
                                    Contact</Link>
                            </div>
                        </div>
                    </div>

                    <div data-aos="zoom-out-down">
                        <p id='seoheading'><b>Some of Our Portfolios</b></p>
                        <div className="container">
                            <div className="row" style={{ marginTop: '5%', marginBottom: '5%' }}>
                                <div className="col-md-4">
                                    <img src={bro1} alt="" width={'100%'}  />


                                </div>
                                <div className="col-md-4">
                                    <img src={bro2} alt="" width={'100%'}  />
                                </div>
                                <div className="col-md-4">
                                    <img src={bro3} alt="" width={'100%'}  />


                                </div>
                            </div>
                        </div>
                    </div>
                    <div >
                        <Cli />
                    </div>

                    <br></br>
                </div>
            </section>
        </div>
    )
}
export default Spamprotection;