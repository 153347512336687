
import './education.css'
import port2 from '../img/3.png';

import { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Helmet } from 'react-helmet';

function Education() {

    useEffect(() => {
        AOS.init({ duration: 2000 });
    }, []);


    return (
        <div>
            <Helmet>

                <title>Best web design company in chennai,digital marketing agency chennai</title>
                <meta name="description" content="WiseWebTek is there for all who are looking for Website Design,Search Engine Optimization SEO,Mobile Responsive Web Design,Social Media Marketing and Digital Marketing Services in Chennai,India-Call us Now." />
                <meta name="keywords" content="web development company chennai,Digital Marketing Companies in Chennai,Website Design Companies in Chennai, SEO Companies in Chennai,best website design services chennai " />

            </Helmet>

            <section id="seofull">
                <div className="container">
                    <div className="row" >
                        <div className='col-md-2'></div>
                        <div className='col-md-2'></div>
                        <div className='col-md-2'></div>
                        <div className='col-md-2'></div>
                        <div className='col-md-2'></div>
                        <div className='col-md-2'></div>
                    </div>





                </div>
                <br></br>
            </section>
        </div>
    )
}
export default Education;