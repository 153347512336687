
import './navigation.css';

// import logo1 from './images/log1.JPEG';
import { Outlet, Link } from "react-router-dom";

import Logo from './images/log1.jpeg';

function Navgat() {
    return (
        <div>
            <nav class="navbar navbar-expand-lg">
                <div class="container-fluid">
                    <div className='col-md-3'>
                        <Link to="/">
                            <img src={Logo} alt="" width={'220px'} /></Link>
                   
                    </div>
                    

                    <div className='col-md-9'>
                        {/* <a class="navbar-brand" href="#">Brand</a> */}
                        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#main_nav" aria-expanded="false" aria-label="Toggle navigation">
                            <span class="fa fa-bars"></span>
                        </button>
                        <div class="collapse navbar-collapse " id="main_nav">
                            <ul class="navbar-nav  ms-auto" >
                                <li class="nav-item active"> <Link to="/" class="nav-link " style={{ color: 'black' }}>Home</Link> </li>
                                <li class="nav-item"><Link to="/aboutus" class="nav-link " style={{ color: 'black' }}>About Us</Link></li>

                                <li class="nav-item dropdown" >
                                    <a class="nav-link dropdown-toggle " style={{ color: 'black' }} href="#Services" data-bs-toggle="dropdown"> Services  </a>
                                    <div class="dropdown-menu " role="menu">
                                        <ul class="list-unstyled" style={{ padding: '10px' }}>
                                            <li><Link to="/webdesign">Web Design</Link></li>
                                            <li><Link to='/logo'>Digital Marketing</Link></li>
                                            <li><Link to='/search-engine-optimization'>Search Engine Optimization (SEO)</Link></li>
                                            <li><Link to='/social-media-marketing'>Social Media Marketing</Link></li>
                                            <li><Link to='/soogle-adwords-advertising'>Google Adwords Advertising </Link></li>
                                            <li><Link to="/e-commerce" >E-commerce Website Development</Link></li>
                                            <li><Link to='/webhosting'>Web Hosting</Link></li>
                                            <li><Link to='/graphicdesign'>Graphic Design </Link></li>
                                            <li><Link to='/video'>Video Editing </Link></li>
                                            <li><Link to='/brochure-design'>Brochure Design</Link></li>

                                        </ul>
                                    </div>
                                </li>
                                <li class="nav-item"><Link to="/portfolio" class="nav-link" style={{ color: 'black' }}>Portfolios</Link></li>
                                <li class="nav-item"><Link to='/client' class="nav-link" style={{ color: 'black' }} > Clients </Link></li>
                                <li class="nav-item"><Link to='/contact' class="nav-link" style={{ color: 'black' }}>Contact Us</Link></li>
                                {/* <li class="nav-item"><Link to='/video' class="nav-link" style={{ color: 'black' }}>Blog</Link></li> */}
                                <li class="nav-item"><Link to='/contact' className="nav-link " id='navenqbtn'>Enquire Now</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </nav>
            <Outlet />

        </div>
    )
}


export default Navgat;