import './sem.css';

import { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Helmet } from 'react-helmet';

function Sem() {

    useEffect(() => {
        AOS.init({ duration: 2000 });
    }, []);

    return (
        <div>
            <Helmet>
                <title>web design chennai,Search Engine Marketing Chennai,Google Adwords Advertising services Chennai,Digital Marketing Companies in Chennai</title>
                <meta name="description" content="web design chennai,Digital Marketing,Google Advertising,Website Design,Search Engine Optimization SEO,Mobile Responsive Web Design,Social Media Marketing,Ecommerce Web Design and Digital Marketing Services in Chennai,India-Call us Now." />
                <meta name="keywords" content="web design company chennai,Google Adwords Advertising Chennai,Digital Marketing Companies in Chennai,Website Design Companies in Chennai, SEO Companies in Chennai, Digital Marketing Company Chennai, Web Design Company Chennai " />

            </Helmet>

            <section id="semfull" >
                <div className="container">
                    <div className="row" >
                        <p className='semheading' data-aos="zoom-out-down">Search Engine Marketing Chennai</p>
                        <div style={{ marginTop: '3%' }}></div>
                        <div className="col-lg-6 order-2 order-lg-1">
                            <img src='https://wisewebtek.com/images/socialmediamarketinginchennai.jpg' alt="My Image" width={'100%'} height={'84%'} data-aos="fade-up-right" />
                        </div>
                        <div className="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 semcontent" data-aos="zoom-out-down">
                            <p id='semsubheading'><b>Introduction to SEM</b></p>

                            <p>
                                what is SEM? SEM Tools,Strategies What is Google Adwords? Google Search Network Adwords Terminologies How Does
                                SEM Work? Structure Of an Adwords Account
                            </p>

                            <p >
                                Campaign Types-Introduction to search,Display
                                (including Videos),Shopping and Mobile Specific
                                Campaign Types and When/Why to use them.</p>

                            <p id='semsubheading'><b>Creation of Search Network Campaign</b></p>

                            <ul>
                                <li className='semcontent'>Landing Pages</li>
                                <li className='semcontent'>Bidding and Budgets</li>
                                <li className='semcontent'>Optimizing the SN Campaign Using the KW Planner,</li>
                                <li className='semcontent'>Match Types,Ad Text Best Practices</li>
                            </ul>

                            <div className='container'>
                                <div classname='row'>
                                    <div classname='col-md-12'>
                                        <button type="button" id="sembtn"> Contact </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br></br>
            </section>
        </div>
    )
}

export default Sem;
