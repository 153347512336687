
import './seo.css';
import port1 from '../img/s1.png';
import port2 from '../img/s2.png';
import port3 from '../img/s3.png';
import seo from '../img/seo.png';

import Cli from '../../home/cli';

import { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Helmet } from 'react-helmet';

function Seo() {

    useEffect(() => {
        AOS.init({ duration: 1600 });
    }, []);

    return (
        <div>
            <Helmet>
                <title>SEO Companies in Chennai, Search Engine Optimization Company Chennai,Digital Marketing Companies in Chennai,Affordable SEO in Chennai</title>
                <meta name="description" content="SEO Company in Chennai which provides the best Search Engine Optimization Services to get listed in Google Search Engine-Call us for SEO" />
                <meta name="keywords" content="SEO Companies in Chennai, Search Engine Optimization Services in Chennai,Best SEO Firm in Chennai" />
            </Helmet>

            <section id="seofull">
                <div className="container">
                    <div className="row" >
                        <p id='seoheading' data-aos="zoom-out-down"><b>Search Engine Optimization (SEO)</b></p>
                        <div style={{ marginTop: '3%' }}></div>
                        <div className="col-lg-6 order-2 order-lg-1">
                            <img src={seo} alt="My Image" width={'100%'} height={'100%'} style={{borderRadius:'10px'}} data-aos="fade-up-right" />
                        </div>
                        <div className="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 seocontent" data-aos="zoom-out-down">
                            <h6><b>What is search engine Optimization</b></h6>
                            <p>
                                Search Engine Optimization (SEO) is a Search Engine Marketing strategy that aims at increasing a website’s
                                performance on the search engines. It is the process of increasing the amount of visitors to a Web site by
                                ranking high in the search results of search engines.
                            </p>
                            <h6><b>Why Search Engine Optimization is Very Important for your Business?</b></h6>
                            <p>
                                The aim of SEO is to increase sales by obtaining qualified business leads from Internet search engines.
                                Achieving this goal is possible with any business of any size.
                            </p>
                        </div>
                    </div>

                    <div className="row">
                        <div className='seocontent' data-aos="zoom-out-down" style={{ marginTop: '1%' }}>
                            <p>If you are serious about your business, you cannot ignore the search engine. Every day, there are millions of
                                searches conducted online. Many people use search engines like Google, Yahoo, MSN etc, to look for business
                                services, information, tips etc.</p>
                            <p>
                                However, if your company website does not rank high on search engines result listing, your competitors that
                                are on top will gladly take your business away, leaving you lose your potential customers. How much effort did
                                you put to bring up your business and do you want your customer go to your competitor? Therefore, SEO becomes
                                very important to your business for your Website. SEO (Search Engine Optimization) is the process of improving
                                the volume of traffic to your website from search engine natural search results.
                            </p>
                            <p>The objective of SEO is to make sure that when your potential customers type in keywords that are related to
                                your business and making your website appear as near to the top as possible.</p>
                        </div>
                    </div>

                    <div data-aos="zoom-out-down">
                        <p id='seoheading'>Our SEO Portfolios</p>
                        <div className="container">
                            <div className="row" style={{ marginTop: '5%', marginBottom:'5%' }}>
                                <div className="col-md-4">
                                    <div className="containerk">
                                        <div className="scrollable" id="img2">
                                            <img src={port1} alt="My Image" width={'100%'} height={'500%'} />
                                        </div>
                                    </div>

                                </div>
                                <div className="col-md-4">
                                    <div className="containerk">
                                        <div className="scrollable" id="img2">
                                            <img src={port2} alt="My Image" width={'100%'} height={'500%'} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="containerk">
                                        <div className="scrollable" id="img2">
                                            <img src={port3} alt="My Image" width={'100%'} height={'500%'} />
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div >
                        <Cli />
                    </div>


                </div>
                <br></br>
            </section>
        </div>

    )
}

export default Seo;