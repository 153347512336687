import './startup.css'

import { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Helmet } from 'react-helmet';

function Startup() {

    useEffect(() => {
        AOS.init({ duration: 2000 });
    }, []);

    return (
        <div>
            <Helmet>
                <title>Web Design for Startups Chennai,Start-up Web Design Package and Pricing  Chennai,Call Now</title>
                <meta name="description" content="We provide Web Design for Startups.Affordable and Professional Web Design for Startups.Checkout the Package and Pricing for Website Design for Startups Chennai- Contact Now" />
                <meta name="keywords" content="Startup Web Design Chennai,Website Design for Startups Chennai, Startup Web Design Package Chennai, Startup Web Design Pricing Chennai, Affordable Website for Small Business, Cheap Website Design Startups, Economical Website Design Chennai, Chennai Startup Web Design, Start-up Website Design Service" />

            </Helmet>

            <section id='web-strtup-full'>
                <div className='container'>
                    <div className='row'>
                        <div Class='col-md-3'></div>
                        <div Class='col-md-6 text-center price-div'>
                            <article className="card">
                                <p>
                                    WEBSITE DESIGN FOR STARTUPS CHENNAI
                                    <b style={{ color: 'pink' }}> @ ₹ 6999</b> CALL 9080248247
                                    Leading Startup Web Designers in Chennai
                                </p>

                                <span className="top span-full"></span>
                                <span className="right span-full"></span>
                                <span className="bottom span-full"></span>
                                <span className="left span-full"></span>
                            </article>
                        </div>
                    </div>
                    <br></br>
                    <br></br>
                    <div className='row'>

                        <div className='col-md-1'>

                        </div>

                        <div className='col-md-5 content-box' data-aos="fade-up">
                            <h4>Get the Right Start .. Start Your Business with Style</h4>
                            <p>Many of the startups are not equipped to have an expensive website and they
                                really lack the resources to create their own website. Here is where
                                WiseWebTek Steps in !</p>

                            <p>We create Affordable Websites for Startups and we support them to grow
                                their business.We love working with startups companies and we guide them
                                with the right strategies of growing their business by digital marketing
                                strategies.</p>
                        </div> &nbsp;&nbsp;&nbsp;&nbsp;

                        <div className='col-md-5 content-box' data-aos="fade-up">
                            <h4>Quick Turnaround and Budget-Friendly</h4>
                            <p>As you are just entering to the market, you need quick turnaround and budget
                                friendly website design services. WiseWebTek provides the best web design
                                services to startups.</p>
                            <h4>Affordable and Professional Website Design for Start-ups</h4>
                            <p>WiseWebTek is focussed on supporting startups with very affordable website
                                design. We design the Professional and High Quality Website at Affordable
                                Price. We also guide and support the startups to reach their business
                                goals. We have a team of expert website designers and developers, who
                                create the best design with the latest technologies.</p>
                        </div>
                    </div>

                    <div>
                        <div className='row pop-up' >
                            <div class='col-md-2'></div>
                            <div class='col-md-7 pop-up1' data-aos="fade-left">
                                <span id='price-heading1'><b>Startup Web Design Package</b></span>
                                <span id='span-price'><b>Just ₹ 6999</b></span>
                                <hr></hr>

                                <p id='price-heading' >Our Web design packages for startups include,</p>
                                <ul>
                                    <li><i class="fa-solid fa-square-check"></i>&nbsp;&nbsp;Single Page Responsive Design (Mobile Compatible) <i class="fa-solid fa-shield-check"></i></li>
                                    <li><i class="fa-solid fa-square-check"></i>&nbsp;&nbsp;Only single revision</li>
                                    <li><i class="fa-solid fa-square-check"></i>&nbsp;&nbsp;Basic Web Hosting for 1 year (250mb space)</li>
                                    <li><i class="fa-solid fa-square-check"></i>&nbsp;&nbsp;Single Domian name registration for 1 year</li>
                                    <button id='buybtn'>Buy Now</button>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div >
                        <p id='logo-subheading1'>Creative Design Services Chennai (Logo design,Brochure design,Banner design etc)</p>
                        <p id='logo-subheading2'><b>How to signup for this service?</b></p>
                        <p className='text-center' ><b>Fill the details below and send to us. Our experts will call you back</b></p>
                    </div>
                    <div className='row' >
                        <div className='col-md-2'></div>
                        <div className='col-md-7' >
                            <form>
                                <div class="mb-3">
                                    <label for="exampleFormControlInput1" class="form-label">Name</label>
                                    <input type="text" class="form-control" id="exampleFormControlInput1" placeholder="Enter your email id" />
                                </div>

                                <div class="mb-3">
                                    <label for="exampleFormControlInput2" class="form-label">Email </label>
                                    <input type="email" class="form-control" id="exampleFormControlInput2" placeholder="Enter your full name" />
                                </div>
                                <div class="mb-3">
                                    <label for="exampleFormControlInput3" class="form-label">Phone</label>
                                    <input type="number" class="form-control" id="exampleFormControlInput3" placeholder="Enter your mobile number" />
                                </div>

                                <div class="mb-3">
                                    <label for="exampleFormControlTextarea1" class="form-label">Message</label>
                                    <textarea class="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
                                </div>
                                <button type="submit" class="btn btn-primary">Submit</button>
                            </form>
                        </div>

                    </div>

                    <div class=' startup-div'>
                        <button type="button" id='start-up-btn'> Contact </button>
                    </div>
                </div>

                <br></br>
            </section>
        </div>
    )
}
export default Startup;