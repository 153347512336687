import './mobileappdevelopment.css'

import { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Helmet } from 'react-helmet';

function Mobileappdevelopment() {


    useEffect(() => {
        AOS.init({ duration: 2000 });
    }, []);

    return (
        <div>
            <Helmet>

            <title>web design and Digital Marketing Companies in Chennai,Mobile Application Development Companies in Chennai</title>
     
        <meta name="description" content="web design and digital marketing companies in chennai,Mobile Application Development Companies in Chennai,Website Design,Search Engine Optimization SEO,Mobile Responsive Web Design,Social Media Marketing and Digital Marketing Services in Chennai,India-Call us Now." />
        <meta name="keywords" content="web design company in chennai,Digital Marketing Companies in Chennai,Mobile Application Development Company Chennai,Android Application Development, IOS Application Development,Website Design Companies in Chennai, SEO Companies in Chennai " />

            </Helmet>

            <section id='mobile-development-full'>
                <div className="container">
                    <div className="row" >
                        <p id='mobile-heading' data-aos="fade-left">Mobile App Development in Chennai</p>
                        <div style={{ marginTop: '3%' }}></div>
                        <div className="col-lg-6 order-2 order-lg-1">
                            <img src='https://wisewebtek.com/images/mobile-apps-dev.jpg' alt="My Image" width={'100%'} height={'80%'} data-aos="fade-right" />

                        </div>
                        <div className="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 " id='mobile-content' data-aos="fade-left">
                            <p className='mobilesubheading'><b>Creating “Mobile Apps”</b></p>
                            <p>
                                The creation of mobile applications is similar to the development of web applications, and therefore has its
                                roots in traditional software development. One critical difference, however, is that mobile apps are often
                                developed specifically to take advantage of the unique features of a particular mobile device.

                                The development of mobile application is very different which depends on the device type and platform.
                                Development environments, programming language and processes vary greatly. For this reason, apps must
                                currently be developed separately for each platform. Due to the different programming interfaces (APIs) code
                                reusability is limited.
                            </p>
                            <p className='mobilesubheading'>Types of mobile Apps</p>
                            <ol>
                                <li>Web</li>
                                <li>Native</li>
                                <li>Hybrid</li>
                            </ol>
                            <p>Creating rich applications across multiple platforms.</p>
                            <p>We customize & convert your existing mobile apps.</p>
                            <p>We deliver quality mobile app re-engineering services.</p>


                        </div>
                    </div>
                </div>

                <div className='container' data-aos="fade-left">
                    <div className='row'>
                        <div className='col-md-3'></div>
                        <div className='col-md-7'>
                            <div className="container">
                                <div className="accordion" id="accordionExample">
                                    <p style={{ textAlign: 'center', fontSize: '22px' }}><b>Types of Development</b></p>
                                    <div className="accordion-item mobile-faq-div ">
                                        <h2 className="accordion-header" id="headingOne">
                                            <button className="accordion-button " type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne"
                                                aria-expanded="true" aria-controls="collapseOne" style={{ borderRadius: '20px', backgroundColor: ' rgb(15, 247, 247)' }}>
                                                <span className='mobile-faq-heading'  >iphone app development</span>
                                            </button>
                                        </h2>
                                        <div id="collapseOne" className="accordion-collapse collapse " aria-labelledby="headingOne"
                                            data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                <p><strong>We know what it requires to make a perfect iPhone application as we
                                                    have already developed over 2500 iPhone apps of different categories. We know
                                                    what can turn a raw app idea into a successful app.</strong> </p>

                                            </div>
                                        </div>
                                    </div>

                                    <br></br>

                                    <div className="accordion-item mobile-faq-div ">
                                        <h2 className="accordion-header" id="headingTwo">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo" style={{ borderRadius: '20px', backgroundColor: ' rgb(15, 247, 247)' }}>
                                                <span className='mobile-faq-heading' >ipad app development</span>
                                            </button>
                                        </h2>
                                        <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo"
                                            data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                <p><strong>With the deep understanding and expertise of native iPad app development services,
                                                    we develop top iPad apps that add value to your business and your users’ lives.</strong> </p>
                                            </div>
                                        </div>
                                    </div>

                                    <br></br>

                                    <div className="accordion-item mobile-faq-div ">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree" style={{ borderRadius: '20px', backgroundColor: ' rgb(15, 247, 247)' }}>
                                            <span className='mobile-faq-heading'>Android App Development</span>
                                        </button>

                                        <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="collapseThree"
                                            data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                <p><strong>Using latest technology and industry experience, our full-stack Android developers
                                                    develop successful Android apps that get funded and covered by Techcrunch, BBC News, and New YorkTimes. </strong></p>

                                            </div>
                                        </div>
                                    </div>

                                    <br></br>

                                    <div className="accordion-item mobile-faq-div ">
                                        <h2 className="accordion-header" id="headingFour">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour" style={{ borderRadius: '20px', backgroundColor: ' rgb(15, 247, 247)' }}>
                                                <span className='mobile-faq-heading' >ipad app development</span>
                                            </button>
                                        </h2>
                                        <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour"
                                            data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                <p><strong>With the deep understanding and expertise of native iPad app development services,
                                                    we develop top iPad apps that add value to your business and your users’ lives.</strong> </p>
                                            </div>
                                        </div>
                                    </div>

                                    <br></br>

                                    <div className="accordion-item mobile-faq-div ">
                                        <button className="accordion-button collapsed " type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive" style={{ borderRadius: '20px', backgroundColor: ' rgb(15, 247, 247)' }}>
                                            <span className='mobile-faq-heading'>Best Website Designs
                                                Low
                                            </span>
                                        </button>

                                        <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="collapseFive"
                                            data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                <p><strong>Location: Multiple Location (Remote) </strong></p>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <button type="button" id="webbtn"> Contact </button>
                            </div>
                        </div>

                    </div>
                </div>
                <br></br>
            </section>
        </div>

    )
}
export default Mobileappdevelopment;