import MyImage from './img/447683-PF2MFT-280-01.png';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom

import './aboutus.css';

import { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Helmet } from 'react-helmet';

function Aboutus() {

  useEffect(() => {
    AOS.init({ duration: 1500 });
  }, []);


  return (
    <div>

      <Helmet>

        <title>web design chennai,Digital Marketing Companies in Chennai,Web Development company,search engine optimization service</title>
        <meta name="description" content="Web design chennai,seo chennai,Digital Marketing chennai,Website Design,Search Engine Optimization SEO,Mobile Responsive Web Design,Social Media Marketing,Ecommerce Web Design and Digital Marketing Services in Chennai,India-Call us Now." />
        <meta name="keywords" content="web design companies in chennai,seo companies in chennai,Digital Marketing Companies in Chennai,Website Design Companies in Chennai, SEO Companies in Chennai, Digital Marketing Company Chennai, Web Design Company Chennai " />

      </Helmet>
      <section id="about">

        <div className="container"   >

          <div className="row" style={{ alignItems: 'center' }} >

            <p id='aboutheading'>About us</p>
            <div style={{ marginTop: '3%' }}></div>
            <div className="col-lg-6 order-2 order-lg-1" data-aos="fade-right">

              <img src={MyImage} className="my-image" alt="My Image" height={'90%'} width={'100%'} />
            </div>
            <div className="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 " id='aboutcontent' data-aos="fade-left">
              <p style={{ fontSize: '18px' }}><b>THE BEST WEBSITE DESIGN COMPANY IN CHENNAI, SEO COMPANY IN CHENNAI</b></p>
              <div id='aboutcontent'>
                <p>
                  WiseWebTek is a Multi National Company, Provides E-commerce Solutions.With our best software Technologies, we will
                  help the Business community to reach their Goals faster.Having supported many companies and individuals to create
                  their online solutions,we will continue to provide the best support and solutions to the global business community.
                </p>
                <p>
                  All our web designs are unique designs. We have designed more than 1000+ websites both in India as well as outsourced clients all over the world.
                </p></div>
              <div className='row'>
                <div className='col-md-12' style={{ textAlign: 'right' }}>
                  <Link to="/contact" className='btn btn-outline-primary btns' >
                    Contact</Link>
                </div>
              </div>
            </div>

          </div>

        </div>
        <br></br>

      </section>
    </div>
  )
}

export default Aboutus;