import './webdesign.css';

import webimg from './img/449936-PF5U7D-119-01-01.png';

import { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Helmet } from 'react-helmet';
import Por from '../../home/por';
import Cli from '../../home/cli';
function Webdesign() {

    useEffect(() => {
        AOS.init({ duration: 2000 });
    }, []);

    return (
        <div>

            <Helmet>

                <title>Web Design Companies in Chennai,Web Design Company in Chennai,Adyar India | Best Website Designing Chennai</title>
                <meta name="description" content="Website Design Companies in Chennai,Best Web Design Company in Chennai,Affordable and High Quality Website Designing Company in Chennai,Adyar,Tamilnadu,India" />
                <meta name="keywords" content="Web Design Companies in Chennai,Best Web Design Company in Chennai,Affordable Website Design Company in Chennai,Adyar,India,Web Design,Web designing,Web Design India,Web Designer,Web Designers India,Web design company india,affordable website design, create a website design,e-commerce website designing,web site development, web site development services,Website Development India,Responsive Website Design,Create Mobile Website,Design Responsive Website,Responsive Web Design Chennai,India" />

            </Helmet>

            <section className="webdesignfull">

                <div className="container">

                    <div className="row" >

                        <h3 id='webdesignheding'><b>Web Design</b></h3>

                        <div style={{ marginTop: '3%' }}></div>
                        <div className="col-md-6 ">

                            <img src={webimg} className="my-image" alt="My Image" height={'100%'} width={'90%'} data-aos="zoom-in-down" />

                        </div>

                        <div className="col-md-6 pt-4 pt-lg-0 " id="webdesigncontent" data-aos="zoom-in-down">
                            <p><b>Web Design Chennai/Responsive Web Design Company in Chennai</b></p>
                            <p>
                                Having many years of experience in Web Design and Development, We can help you to design your website
                                and with our advanced E-Commerce Solutions, we will help you to boost your business with the power
                                of internet.
                            </p>
                            <p><b>Responsive ( Mobile, Tablet Compatible ) Website Design Chennai.</b></p>
                            <p>Make Your Website Compatible With Mobile, Tablet.</p>
                            <p>

                                Responsive Web Design is a web design approach
                                which allows the website to adapt to the size of the screen it is being viewed on. Responsive Web
                                Design will allow a single website to support many devices such as Mobiles,Tablets etc. Nowadays
                                People are using Mobile Phones, Tablets to browse the internet.So it is important that your website
                                should adapt to any screen size. So if you update your web design as Responsive design, your
                                customer can access your website perfectly through Mobiles, Tablets.
                            </p>


                        </div>

                        <div className='col-md-3'></div>
                        <div className='col-md-3'></div>
                        <div className='col-md-6' data-aos="zoom-in-down">
                            <h4>Why Responsive Design is very important for your Website?</h4>
                            <div className="container">
                                <div className="accordion" id="accordionExample">
                                    <div className="accordion-item addd" >
                                        <h2 className="accordion-header" id="headingOne">
                                            <button className="accordion-button " id='webfaqbtn' type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne"
                                                aria-expanded="true" aria-controls="collapseOne">
                                                <span className="text-center"> Time and Money
                                                </span>
                                            </button>
                                        </h2>
                                        <div id="collapseOne" className="accordion-collapse collapse " aria-labelledby="headingOne"
                                            data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                <p><strong>If you are not opting Responsive Design, then you will have to maintain multiple sites for Mobile, Tablet etc. This will cost you good amount of money and also maintaining multiple websites is a big overhead. It will eat up your time too . But if you are opting for a Responsive Web Design, then one single website will get adapt to any screen size. The Responsive website will open perfectly in computers,mobile phones,tablets etc. This will reduce the time and money involved in maintaining multiple websites drastically.</strong> </p>
                                            </div>
                                        </div>
                                    </div>

                                    <br></br>

                                    <div className="accordion-item addd" >
                                        <h2 className="accordion-header" id="headingTwo">
                                            <button className="accordion-button collapsed " id='webfaqbtn' type="button" data-bs-toggle="collapse"
                                                data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo" >
                                                <span > Your Customer is trying to access your Website through Mobiles and Tablets
                                                </span>
                                            </button>
                                        </h2>
                                        <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo"
                                            data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                <p><strong>The internet traffic originating from Mobile devices are increasing day by day. Your customer may be using smart phone or tablet to browse your website. But if your website is not updated to Responsive Design, it will not display properly in devices like mobile,tablet. This may make the customer to choose your competitor who is updated to Responsive Design. This can also lead in losing a potential customer.</strong> </p>
                                            </div>
                                        </div>
                                    </div>

                                    <br></br>

                                    <div className="accordion-item addd">
                                        <button className="accordion-button collapsed " id='webfaqbtn' type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree" >
                                            <span > Our Specialities includes
                                            </span>
                                        </button>
                                        <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="collapseThree"
                                            data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                <p><strong></strong></p>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div>
                            <Por />

                            <Cli />
                        </div>


                    </div>
                </div>
                <br></br>
            </section>
        </div>

    )

}
export default Webdesign;