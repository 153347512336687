import './google-adword.css';

import { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import google from '../img/google.png';

function GoogleAdword() {

    useEffect(() => {
        AOS.init({ duration: 2000 });
    }, []);

    return (
        <div>
            <Helmet>

                <title>website designing services chennai,Google Adwords Advertising Services in Chennai,Digital Marketing Companies in Chennai,Top 10 Web Design Comapnies in Chennai</title>
                    <meta name="description" content="web design companies chennai,Google Adwords Advertising,Digital Marketing Companies in Chennai,Website Design Services from WiseWebTek is very useful for people looking for Web Design,Search Engine Optimization,SEO and Digital Marketing Services in Chennai-Call us Today" />
                   <meta name="keywords" content="web design company in chennai,Google Adwords Advertising Chennai,Digital Marketing Companies in Chennai,Web Design Companies in Chennai designing website at economical price" />
                   
            </Helmet>
            <section id="bgcolors">
                <div className="container">
                    <div className="row">
                        <p id='googleheading' data-aos="zoom-out-down"><b>Google Adwords Advertising</b></p>
                        <div style={{ marginTop: '3%' }}></div>
                        <div className="col-lg-6 order-2 order-lg-1">
                            <img src={google} alt="" style={{ borderRadius: '10px' }} width={'100%'} data-aos="fade-up-right" />
                        </div>
                        <div className="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1" id='googlecontent' data-aos="zoom-out-down">

                            <p id='googlesubheading'><b>Google Adwords Advertising Company in Chennai
                            </b></p>
                            <p> <b>Why Google AdWords?</b></p>
                            <p>
                                Attract more customers.

                                Whether you’re looking to bring in new website visitors, grow online sales, get the phones ringing or keep
                                customers coming back for more, Google AdWords can help.
                            </p>

                            <div>
                                <div class="container">
                                    <div class="accordion" id="accordionExample">

                                        <div class="accordion-item googlefaq">
                                            <h2 class="accordion-header" id="headingOne">
                                                <button class="accordion-button faqbtn" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne"
                                                    aria-expanded="true" aria-controls="collapseOne" style={{ borderRadius: '40px' }}>
                                                    <span class="text-center">Reach The Right People At The Right Time</span>
                                                </button>
                                            </h2>
                                            <div id="collapseOne" class="accordion-collapse collapse " aria-labelledby="headingOne"
                                                data-bs-parent="#accordionExample">
                                                <div class="accordion-body">
                                                    <p>Your business gets found by people on Google precisely when they’re searching for the things that you offer. </p>

                                                </div>
                                            </div>
                                        </div>

                                        <br></br>
                                        <div class="accordion-item googlefaq">
                                            <h2 class="accordion-header" id="headingTwo">
                                                <button class="accordion-button collapsed faqbtn" type="button" data-bs-toggle="collapse"
                                                    data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo" style={{ borderRadius: '40px' }}>
                                                    <span >Advertise Locally Or Globally</span>
                                                </button>
                                            </h2>
                                            <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
                                                data-bs-parent="#accordionExample">
                                                <div class="accordion-body">
                                                    <p>Target your ads to customers in certain countries, regions or cities — or within a set distance from your business or shop.</p>
                                                </div>
                                            </div>
                                        </div>

                                        <br></br>
                                        <div class="accordion-item googlefaq">
                                            <button class="accordion-button collapsed faqbtn" type="button" data-bs-toggle="collapse"
                                                data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree" style={{ borderRadius: '40px' }}>
                                                <span>If You Need Us,We're Here </span>
                                            </button>

                                            <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="collapseThree"
                                                data-bs-parent="#accordionExample">
                                                <div class="accordion-body">
                                                    <p>Get the support from us for advertising your business in google.Kindly call us on (+91) 9080248247</p>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className='col-md-12' style={{ textAlign: 'right', marginTop: '20px' }}>
                                    <Link to="/contact" className='btn btn-outline-primary btns' >
                                        Contact</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br></br>
            </section>
        </div>
    )
}
export default GoogleAdword;