
import './webhosting.css';

import Hostimg from './img/6477707-01.png';

import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Helmet } from 'react-helmet';
import Por from '../../home/por';
function Webhosting() {

  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);

  return (
    <div >

      <Helmet>
        <title>Web design and Digital Marketing Companies in Chennai,Web hosting,Affordable and Cheap Web Hosting Services Chennai,Adyar</title>

        <meta name="description" content="web design companies in chennai,Website Hosting, Affordable Web Hosting,High Quality server hosting,Linux web hosting Chennai" />
        <meta name="keywords" content="web design companies in chennai,Digital Marketing Companies in Chennai,Web hosting Services in Chennai,Web site hosting Companies in chennai,VPS Hosting,Dedicated Web Hosting" />

      </Helmet>

      <section id="hostingfull" >
        <div className="container">
          <div className="row" >
            <p id='hostingheading'><b>Web Hosting</b></p>
            <div style={{ marginTop: '3%' }}></div>

            <div className="col-lg-6 order-2 order-lg-1">
              <img src={Hostimg} className="my-image" alt="My Image" height={'100%'} width={'100%'} data-aos="zoom-in-down" />

            </div>

            <div className="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 " id='hostingcontent' data-aos="zoom-in-down">

              <p className='hosting-sub-heading'><b>Web Hosting Chennai</b></p>
              <p>
                Get your website designed and hosted with the no:1 web design company in chennai

                Linux Hosting and Window Hosting

                Linux Hosting and Window Hosting Packages include,
              </p>
              <p>
                <b>
                  Storage space required<br></br>
                  Bandwidth required<br></br>
                  Cpanel<br></br>
                  Email ( Webmail)<br></br>
                  MYSQL ( Database)<br></br>
                </b>
              </p>

            </div>

          </div>

          <div>
            <Por />
          </div>

          <div className='row'>
            <div className='col-md-12' style={{ textAlign: 'center' }}>
              <Link to="/portfolio" className='btn btn-outline-primary btns'>
                Read more</Link>
            </div>
          </div>
        </div>
        <br></br>
      </section>
    </div>
  )
}
export default Webhosting;