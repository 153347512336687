import './web-accessibility.css'
import port1 from '../img/2.png';

import { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Helmet } from 'react-helmet';

function Webaccessibility() {

    useEffect(() => {
        AOS.init({ duration: 2000 });
    }, []);

    return (
        
        <div>
            <Helmet>

                <title>Accessible Website Design Chennai,Web design for Disabled,Accessibility Web Design company chennai,Accessibility Web Design India,Chennai.</title>
                <meta name="description" content="WiseWebTek Designs websites with Web Accessibility. Accessible Website Design,Web Accessibility,Web Design for Disabled,Web Design with Accessibility,Design Websites with Accessibility India,Chennai." />
                <meta name="keywords" content="Accessible Web Design Chennai,Web Accessibility India,Accessible web design india,chennai,website accessibility,accessible web design company,web design for disabled,accessible website design company in chennai,web design with accessibility" />

            </Helmet>

            <section id="webacess-full"  >
                <div className="container">
                    <div className="row" >
                        <p className='web-ac-heading' data-aos="fade-left">Accessibility Website Design Chennai</p>
                        <div style={{ marginTop: '3%' }}></div>

                        <div className="col-lg-6 order-2 order-lg-1">

                            <img src='https://wisewebtek.com/images/accessible-website-design.png' alt="My Image" data-aos="fade-right" width={'100%'} height={'84%'} />
                        </div>
                        <div className="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 " data-aos="fade-left" >
                            <p className='web-ac-subheading'><b>WHAT IS WEB ACCESSIBILITY?</b></p>

                            <p>
                                Web accessibility means, websites are designed and developed in such a way that people with disabilities can use them.
                            </p>

                            <p>
                                Web accessibility encompasses all disabilities that affect access to the Web, including:
                            </p>


                            <div class="container">
                                <div class="hover-box">
                                    <div class="row">
                                        <div class="col-sm-12 col-md-6">
                                            <span class="fullspan">
                                                <span class="namespan">Auditory</span>
                                                <div class="spanicon">
                                                    <i class="fa-regular fa-circle-check"></i>             </div>
                                            </span>
                                        </div>
                                        <div class="col-sm-12 col-md-6">
                                            <span class="fullspan" >
                                                <span class="namespan">Cognotive</span>
                                                <div class="spanicon heart">
                                                    <i class="fa-regular fa-circle-check"></i>
                                                </div>
                                            </span>
                                        </div>
                                        <div class="col-sm-12 col-md-6">
                                            <span class="fullspan">
                                                <span class="namespan">Neurological</span>
                                                <div class="spanicon book">
                                                    <i class="fa-regular fa-circle-check"></i>        </div>
                                            </span>
                                        </div>
                                        <div class="col-sm-12 col-md-6">
                                            <span class="fullspan">
                                                <span class="namespan">Physical</span>
                                                <div class="spanicon brain">
                                                    <i class="fa-regular fa-circle-check"></i>          </div>
                                            </span>
                                        </div>
                                        <div class="col-sm-12 col-md-6">
                                            <span class="fullspan">
                                                <span class="namespan">Speech</span>
                                                <div class="spanicon book">
                                                    <i class="fa-regular fa-circle-check"></i>       </div>
                                            </span>
                                        </div>
                                        <div class="col-sm-12 col-md-6">
                                            <span class="fullspan">
                                                <span class="namespan">Visual</span>
                                                <div class="spanicon book">
                                                    <i class="fa-regular fa-circle-check"></i>        </div>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div data-aos="fade-left">

                        <p className='web-ac-subheading1' ><b>DESIGN WEBSITES WITH WEB ACCESSIBILITY FROM THE LEADING WEB DESIGN COMPANY IN CHENNAI</b></p>
                        <p className='web-ac-subheading'><b>Web accessibility also benefits people without disabilities, for eg:</b></p>
                    </div>

                    <div className='wrapper' data-aos="fade-left">
                        <div className='box'>
                            <div className='items'>
                                <i class="fa-solid fa-display webicon"></i>
                                <p>People using mobile phones, smart watches, smart TVs, and other devices with small screens</p>
                            </div>
                        </div>
                        <div className='box'>
                            <div className='items'>
                                <i class="fa-solid fa-user"></i>
                                <p>Older people with changing abilities due to ageing</p>
                            </div>
                        </div>
                        <div className='box'>
                            <div className='items'>
                                <i class="fa-solid fa-circle-half-stroke"></i>
                                <p>People with “situational limitations” such as in bright sunlight or in an environment where they cannot listen to audio</p>
                            </div>
                        </div>
                        <div className='box'>
                            <div className='items'>
                                <i class="fa-sharp fa-solid fa-earth-americas"></i>
                                <p>People using a slow Internet connection, or who have limited or expensive bandwidth</p>
                            </div>
                        </div>
                    </div>
                    <div data-aos="fade-left">
                        <p className='web-ac-subheading'><b>Design Website with Web Accessibility Chennai</b></p>
                        <p>WiseWebTek's Expert Team will design websites with Web Accessibility.
                            We have designed many websites with accessibility. Get your accessible website designed from the leading website design company in chennai.</p>
                        <p>Checkout some of our Web Accessibilty Portfolios below,</p>

                    </div>
                    <div className='container' data-aos="fade-left">
                        <div className='row'>
                            <div className='col-md-6'>

                                <div className="webaccontainer">
                                    <div className="wegacscrollable" >
                                        <img src={port1} alt="My Image" width={'100%'} height={'500%'} />
                                    </div>

                                </div>
                            </div>
                            <div className='col-md-6'>

                            </div>
                        </div>
                    </div>


                    <div className='row'>
                        <div classname='col-md-12' data-aos="fade-left">
                            <button type="button" id="webacbtn"> Contact </button>
                        </div>
                    </div>
                </div>
                <br></br>
            </section>
        </div>
    )
}
export default Webaccessibility;
