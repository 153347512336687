import MyImages from './images/2592288.png';

import './section2yt.css';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import { useEffect } from 'react';

import AOS from 'aos';
import 'aos/dist/aos.css';

function Section2() {

    useEffect(() => {
        AOS.init({ duration: 2000 });
    }, []);

    return (
        <div className='s2bgcolor'>
            <div >
                <div className="container" >
                    <div className="row">
                        <div className="col-md-6" id="youtubecontentdiv" data-aos="fade-up-right">
                            <p className="youtubecontent1" ><b>Digital Marketing Services/Agency in Chennai</b></p>
                            <p className="youtubecontent2">Do you want your Website to be on top in Google Listing ?
                                We can provide you the best SEO Services . WiseWebTek, a leading professional web design and digital
                                marketing company in chennai, providing the best web design and digital marketing services at
                                affordable price.</p>
                        </div>
                        <div className="col-md-6" id="youtubevideodiv" >
                            <p className="text-center youtubecontent1"><b>Our Corporate Video</b></p>
                            <iframe width="100%" height="300px"
                                src="https://www.youtube.com/embed/aExXcUkGcgY" title="Launch of Corporate Video" frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowfullscreen style={{borderRadius:'10px'}} data-aos="fade-left"></iframe>
                        </div>
                    </div>
                </div>
            </div>


            <div style={{ marginTop: '4%' }}>
                <div className="container">
                    <div className="row" style={{ alignItems: 'center' }}>
                        <div className="col-md-6" id="youtubevideodiv1">
                            <div data-aos="fade-right">

                                <img src={MyImages} alt="My Image" width={'70%'} height={"400px"} />

                            </div>

                        </div>
                        <div className="col-md-6" width="100%" id="youtubecontentdiv1" data-aos="fade-up-left" >
                            <p className="youtubecontent1"><b>Web Design Company in Chennai - Our Speciality</b></p>
                      
                            
                            <ul style={{ listStyleType: "square" }}>
                                <li>High quality and professional service</li>
                                <li>Expert team</li>
                                <li >Prompt support</li>
                            </ul>

                            <div>
                                <Link to="/aboutus" className='btn btn-outline-primary btns'>
                                   Read more</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Section2;