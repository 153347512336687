


import Section1 from "./section1";
import Section2 from "./section2yt";
import Section3 from "./section3service";
import Section4 from "./section4portfolio";
// import Section5 from "./section5faq";
import Section6 from "./section6client";
import Section7 from "./section7testimonial";
import Section8 from "./section8footer";

// import BlogSection from '../blog-index/BlogSection';


function Home() {

    return (
        <div>
            <Section1 />
            <Section2 />
            <Section3 />
            <Section4 />
            {/* <Section5 /> */}
            <Section7 />
            <Section6 />




        </div>
    )
}
export default Home;