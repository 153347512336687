import './geo.css'

import { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Helmet } from 'react-helmet';

function Geo() {

    useEffect(() => {
        AOS.init({ duration: 2000 });
    }, []);

    return (
        <div>
            <Helmet>
                <title>Web designing company in chennai,best web design and digital marketing chennai</title>
                <meta name="description" content="Website Design chennai,Search Engine Optimization SEO chennai,Mobile Responsive Web Design,Social Media Marketing and Digital Marketing Services in Chennai,India-Call us Now." />
                <meta name="keywords" content="website designing company in chennai,Digital Marketing Companies in Chennai,Website Design Companies in Chennai, SEO Companies in Chennai " />

            </Helmet>

            <section id="geofull">
                <div className="container">
                    <div className="row" >
                        <h3 id='geoheading' data-aos="fade-left">Why Should I use GeoLocate?</h3>

                        <div style={{ marginTop: '3%' }}></div>

                        <div className="col-lg-6 ">
                            <img src='https://wisewebtek.com/images/geo-locator-services.jpg' alt="My Image" width={'100%'} height={'90%'} data-aos="fade-up-right" />

                        </div>

                        <div className="col-lg-6 pt-4 pt-lg-0 geocontent" data-aos="fade-left" >
                            <p id='geosubheading'><b>Geo Locator</b></p>
                            <p>
                                The software GeoLocate will track the location of the visitors of your website.You can know which location
                                your website visitors are from.
                            </p>

                            <p >
                                Campaign Types-Introduction to search,Display
                                (including Videos),Shopping and Mobile Specific
                                Campaign Types and When/Why to use them.</p>

                            <p><b>Why Should I use GeoLocate?</b></p>

                            <p>GeoLocate will capture the location of your website visitors.</p>

                            <p>Once you know where most of the visitors are from. You can do more targeted marketing to that particular
                                location.</p>

                        </div>

                        <div className='geocontent' style={{ marginTop: '2%' }} data-aos="fade-left">


                            <p><b>Requirements</b></p>

                            <ul>
                                <li>Minimum PHP Version 5.6</li>
                                <li>SSL Certification, HTTPS Redirect</li>
                                <li>Privacy Policy (As Per Government Law)</li>
                            </ul>

                            <p><b>You will have an admin panel with login gateway. Through this you can view the captured locations of the
                                visitors</b></p>

                            <div>
                                <button type="button" id="geobtn"> Contact </button>
                            </div>
                        </div>
                    </div>
                </div>
                <br></br>
            </section>
        </div>
    )
}
export default Geo;