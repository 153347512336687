import { Dialog, DialogContent, DialogContentText, DialogTitle, Button } from "@mui/material";
import { useState } from "react";

import './enqurie-now.css';

function Enqurie() {

    return (

        <Dialog open={true}  maxWidth='lg'>

            <DialogTitle> Form</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <form>
                        <div class="mb-3">
                            <label for="exampleFormControlInput1" class="form-label">Name</label>
                            <input type="text" class="form-control" id="exampleFormControlInput1" placeholder="Enter your email id" />
                        </div>

                        <div class="mb-3">
                            <label for="exampleFormControlInput2" class="form-label">Email </label>
                            <input type="email" class="form-control" id="exampleFormControlInput2" placeholder="Enter your full name" />
                        </div>
                        <div class="mb-3">
                            <label for="exampleFormControlInput3" class="form-label">Phone</label>
                            <input type="number" class="form-control" id="exampleFormControlInput3" placeholder="Enter your mobile number" />
                        </div>
                        <div class="mb-3">
                            <label for="exampleFormControlTextarea1" class="form-label">Message</label>
                            <textarea class="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
                        </div>
                        <button type="submit" class="btn btn-primary">Submit</button>
                    </form>
                </DialogContentText>
            </DialogContent>
        </Dialog>


    )
}
export default Enqurie;