import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './section6client.css';

import Client1 from './images/client/1.png';
import Client2 from './images/client/2.png';
import Client3 from './images/client/3.png';
import Client4 from './images/client/4.png';
import Client5 from './images/client/5.png';
import Client6 from './images/client/6.png';
import Client7 from './images/client/7.png';
import Client8 from './images/client/8.png';
import Client9 from './images/client/9.png';
import Client10 from './images/client/10.png';

function Section6() {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    cssEase: 'linear',
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  return (
    <div className='client' style={{ marginTop: '3%' }}>
      <div id='clientfull'>
        <p className='text-center' id='client-heading'><b>Our Clients</b></p>
        <Slider {...settings} v >
          <div className='client-logo'>
            <img src={Client1} alt='' width={'230px'} />
          </div>
          <div className='client-logo'>
            <img src={Client2} alt='' width={'230px'} />
          </div>
          <div className='client-logo'>
            <img src={Client3} alt='' width={'230px'} />
          </div>
          <div className='client-logo'>
            <img src={Client4} alt='' width={'230px'} />
          </div>
          <div className='client-logo'>
            <img src={Client5} alt='' width={'230px'} />
          </div>
          <div className='client-logo'>
            <img src={Client6} alt='' width={'230px'} />
          </div>

          <div className='client-logo'>
            <img src={Client7} alt='' width={'230px'} />
          </div>

          <div className='client-logo'>
            <img src={Client8} alt='' width={'230px'} />
          </div>

          <div className='client-logo'>
            <img src={Client9} alt='' width={'230px'} />
          </div>

          <div className='client-logo'>
            <img src={Client10} alt='' width={'230px'} />
          </div>

        </Slider>
        <br></br>
      </div>
    </div>
  );
}

export default Section6;
