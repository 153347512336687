import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from './home/layout'; // Import the new Layout component

import Logocreative from './services/web design/logocreative';
import Aboutus from './about/aboutus';
import GoogleAdword from './services/digital morketing/google-adword';
import Sem from './services/digital morketing/sem';
import Smm from './services/digital morketing/socialmediamorketing';
import Ecommerce from './services/web design/ecommerce';
import Seo from './services/digital morketing/seo';
import Brochure from './services/web security/brochure-design';
import Graphicdesign from './services/web security/graphicdesign';
import Webdesign from './services/web design/webdesign';
import Webhosting from './services/web design/webhosting';
import Geo from './services/development/geo';
import Hotalbooking from './services/development/hotalbookinengine';
import Education from './services/development/educationsoftware';
import Home from './home/home';
import Port from './portfolio/portfolio';
import Webaccessibility from './services/webaccessibility/web-accessibility';
import Startup from './services/webdesign for startup/startup';
import Mobileappdevelopment from './services/development/mobileappdevelopment';
import Client from './client/client';
import Contactpage from './contact/contact';
import Videopage from './video/video';
import Enqurie from './home/enqu/enqurie-now';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="aboutus" element={<Aboutus />} />
          <Route path="webdesign" element={<Webdesign />} />
          <Route path="e-commerce" element={<Ecommerce />} />
          <Route path="webhosting" element={<Webhosting />} />
          <Route path="logo" element={<Logocreative />} />
          <Route path="search-engine-optimization" element={<Seo />} />
          <Route path="search-engine-marketing" element={<Sem />} />
          <Route path="social-media-marketing" element={<Smm />} />
          <Route path="soogle-adwords-advertising" element={<GoogleAdword />} />
          <Route path="graphicdesign" element={<Graphicdesign />} />
          <Route path="brochure-design" element={<Brochure />} />
          <Route path="geo-locate" element={<Geo />} />
          <Route path="hotal-boocking-engine" element={<Hotalbooking />} />
          <Route path="education-software" element={<Education />} />
          <Route path="mobile-app-development" element={<Mobileappdevelopment />} />
          <Route path="web-accessibility" element={<Webaccessibility />} />
          <Route path="web-design-for-startup" element={<Startup />} />
          <Route path="portfolio" element={<Port />} />
          <Route path="client" element={<Client />} />
          <Route path="contact" element={<Contactpage />} />
          <Route path="video" element={<Videopage />} />
          <Route path="enquire-now" element={<Enqurie />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
